// Containers
export { PersonalDetailsContainer } from "./components/containers/PersonalDetailsContainer/PersonalDetailsContainer.jsx"; // Contexts

export * from "./contexts/CustomerContext/CustomerContext";
export { CustomerDocument, useCustomerQuery } from "./generated/graphql"; // Mocks

export * from "./mocks/Customer/apollo";
export * from "./mocks/Customer/data";
export { handler as customerHandler } from "./mocks/Customer/Customer.query"; // Utilities

export { logIn, getLoginUrl } from "./utils"; // Constants

export * from "./consts";