import { LOGIN_URL } from "../consts/index.js";
export var logIn = function logIn(_ref) {
  var redirectDestination = _ref.redirectDestination,
      returnToSelf = _ref.returnToSelf;

  if (true) {
    window.location.assign(getLoginUrl({
      redirectDestination: redirectDestination,
      returnToSelf: returnToSelf
    }));
  }
};
export var getLoginUrl = function getLoginUrl(_ref2) {
  var redirectDestination = _ref2.redirectDestination,
      returnToSelf = _ref2.returnToSelf;
  var returnTo = redirectDestination || (returnToSelf && true ? "".concat(window.location.pathname).concat(encodeURIComponent(window.location.search)) : undefined);
  return "".concat(LOGIN_URL).concat(returnTo ? "?returnTo=".concat(returnTo) : "");
};